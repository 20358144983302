body, span {
  box-sizing: border-box;
  margin: 0;
}

h1, h2, h3, h4 {
  color: #FFFFFF;
  margin: 0;
}
h1 { /* Banner, CartQuantity, Product Price */
  font-family: 'Roboto Condensed', sans-serif;;
  font-size: 48px;
}
h2 { /* Headers */
  font-family: 'Roboto Condensed', sans-serif;;
  font-size: 36px;
  font-weight: 600;
}
h3 { /* PrimaryBtn, CircleBtn, Cart DetailsLabel, Secondary Headers */
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  font-weight: 700;
}
h4 { /* Tertiary Text (Cart Inc/Dec) */
  font-family: 'Roboto Condensed', sans-serif;;
  font-size: 16px;
  font-weight: 700;
}
li { /* Product Details */
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  font-weight: 500;
}